import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-santa-maria-california.png'
import image0 from "../../images/cities/scale-model-of-santa-maria-valley-discovery-museum-in-santa-maria-california.png"
import image1 from "../../images/cities/scale-model-of-santa-maria-museum-of-flight-in-santa-maria-california.png"
import image2 from "../../images/cities/scale-model-of-calalaska-helicopters-inc-in-santa-maria-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Santa Maria'
            state='California'
            image={image}
            lat='34.9530337'
            lon='-120.43571910000003'
            attractions={ [{"name": "Santa Maria Valley Discovery Museum", "vicinity": "705 S McClelland St, Santa Maria", "types": ["museum", "point_of_interest", "establishment"], "lat": 34.94546100000001, "lng": -120.43509899999998}, {"name": "Santa Maria Museum of Flight", "vicinity": "3015 Airpark Dr, Santa Maria", "types": ["museum", "point_of_interest", "establishment"], "lat": 34.9049651, "lng": -120.4547551}, {"name": "Calalaska Helicopters Inc", "vicinity": "3123 Liberator St, Santa Maria", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 34.9018698, "lng": -120.4503277}] }
            attractionImages={ {"Santa Maria Valley Discovery Museum":image0,"Santa Maria Museum of Flight":image1,"Calalaska Helicopters Inc":image2,} }
       />);
  }
}